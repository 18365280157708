const config = {
    api: {
        "API_URL": "https://apiv1.ninansburger.com",
        'BASE_URL': "https://apiv1.ninansburger.com/api/web"
        // "API_URL": "http://localhost:8000",
        // 'BASE_URL': "http://localhost:8000/api/web"
    },
    googleMapApiKey: "AIzaSyDm5f-vq7i2AjRlIpNXNeHdL6wZzjfILg8"
}

export default config;