// AddressContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    const storedAddress = localStorage.getItem('selectedAddress');
    try {
        if (storedAddress) {
            setSelectedAddress(JSON.parse(storedAddress));
        }
    } catch (error) {
        console.error('Error parsing stored address:', error);
    }
}, []);


  const updateSelectedAddress = (newAddress) => {
    setSelectedAddress(newAddress);
    localStorage.setItem('selectedAddress', JSON.stringify(newAddress));
  };

  return (
    <AddressContext.Provider value={{ selectedAddress, updateSelectedAddress }}>
      {children}
    </AddressContext.Provider>
  );
};

export const useAddress = () => useContext(AddressContext);
