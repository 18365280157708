import React, { useContext, useEffect, useMemo, useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
// import { userProfileService } from 'redux/reducers/home/homeAPI';

import { setAuthToken, getAuthToken, getLocalStorageData, setLocalStorageData } from '../service/utilities';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children, initialStatus = false }) {
  const [isUserLoggedIn, setUserLoggedIn] = useState(initialStatus);
  const [userData, setUserData] = useState({ name: '', emailId: '' });

  function updateUserState(status) {
    setUserLoggedIn(status);
  }

  function updateUserData(data) {
    setLocalStorageData("userdata", JSON.stringify({name: data.name,emailId: data.emailId}))
    setUserData({...userData, ...data});
  }

  async function updateUserToken(token, data) {
    setUserLoggedIn(true);
    setAuthToken(token);
    if (data) updateUserData(data);
  }

  function getLocalStorageUserDetails() {
    const newData = getLocalStorageData('userdata')
    setUserData({...userData,...newData})
  }
  useEffect(() => {
    getLocalStorageUserDetails()
  }, []);

  return (
    <AuthContext.Provider value={{
      isUserLoggedIn,
      userData,
      updateUserState,
      updateUserToken,
      updateUserData,
    }}>
      {children}
    </AuthContext.Provider>
  );
}
