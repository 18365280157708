import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LoginFormComponent from './LoginFormComponent';
import RegisterFormComponent from './RegisterFormComponent';
import { register, login, verifyOtp, getCustomerAddressList, createAddress } from '../../redux/reducer/home/homeAPI';
import { useDispatch } from 'react-redux';
import { updateName } from '../../redux/reducer/home/homeSlice';
import { useAddress } from "context/AddressContext";

import AddDeliveryLocationModal from 'components/map';
import { setAuthToken, setLocalStorageData, setUserDetail } from 'service/utilities';
import { AuthContext } from 'context/AuthContext';

const AuthSidebar = ({ isOpen, toggleSidebar, triggerCallback }) => {
  const dispatch = useDispatch()

  const { updateSelectedAddress } = useAddress();
  const { updateUserData } = useContext(AuthContext);

  const [activeSection, setActiveSection] = useState('login');
  const [loginResponse, setLoginResponse] = useState({ mobileNumber: '', session_id: '' })
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  const initialValues = {
    mobileNumber: '',
    emailId: '',
    name: '',
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setShowOTPInput(false);
    validation.resetForm();
    registerValidation.resetForm();
  };


  const toggleLocationModal = () => {
    setIsLocationModalOpen(!isLocationModalOpen);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobileNumber: '',
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Enter Your Mobile Number'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await dispatch(login({ ...values }));
        if (response?.payload?.status === 200 && response?.payload?.data) {
          // dispatch(updateName(response?.payload?.data?.name));
          setLoginResponse({
            ...loginResponse,
            mobileNumber: response?.payload?.data?.mobileNumber,
            session_id: response?.payload?.data?.session_id
          })
          setShowOTPInput(true);
        } else {
          setActiveSection('register');
        }
      } catch (error) {
        console.error('Error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  });



  const registerValidation = useFormik({
    enableReinitialize: true,
    // initialValues: {
    //   mobileNumber: '',
    //   emailId:'',
    //   name:''
    // },
    initialValues,
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Enter Your Mobile Number'),
      emailId: Yup.string()
        .matches(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, 'Enter a valid email Id')
        .required('Enter Your email Id'),
      name: Yup.string().required('Enter Your Name'),
    }),

    onSubmit: async (values) => {
      try {
        setRegisterLoading(true);
        const response = await dispatch(register({ ...values }));

        if (response?.payload?.status === 200 && response?.payload?.data) {
          setLoginResponse({
            ...loginResponse,
            mobileNumber: response?.payload?.data?.mobileNumber,
            session_id: response?.payload?.data?.session_id
          })
          dispatch(updateName(response?.payload?.data?.name));
          setShowOTPInput(true);

          setRegisterLoading(false);
        } else {
          setRegisterLoading(false);
        }
      } catch (error) {
        console.error('Error occurred during registration:', error);
        setRegisterLoading(false);
      }
    }
  });

  const handleVerifyOtp = async () => {
    try {
      const otpError = validateOtp(otp);
      if (otpError) {
        setOtpError(otpError);
        return;
      }

      const payload = {
        session_id: loginResponse.session_id,
        mobileNumber: loginResponse.mobileNumber,
        otp: otp
      }

      setOtpLoading(true);
      const response = await dispatch(verifyOtp(payload));
      if (response?.payload?.status === 200 && response?.payload?.data) {
        const res = response?.payload?.data
        setAuthToken(res?.token)
        updateUserData(res)
        if(res.customerAddress){
        console.log('res.customerAddress',res.customerAddress)
          const setAddress = {
            lat: res?.customerAddress?.location?.coordinates[1],
            lng: res?.customerAddress?.location?.coordinates[0],
            street: res?.customerAddress?.street,
            _id: res?.customerAddress?._id,
            type: res?.customerAddress?.type,
            othersType: res?.customerAddress?.othersType,
            landmark: res?.customerAddress?.landmark,
          }
          updateSelectedAddress(setAddress)
        }
        setOtp('');
        validation.resetForm();
        setShowOTPInput(false);
        toggleSidebar();
        triggerCallback()
       
      } else if(response.payload.status === 409){
        setOtp('');
      }
    } catch (error) {
      setOtpLoading(false);
    } finally {
      setOtpLoading(false);
    }
  };


  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };
  const handleEditClick = () => {
    setShowOTPInput(false);
    setActiveSection('login');
    setOtp('')
  };

  const validateOtp = (otp) => {
    if (!otp) {
      return 'Please enter OTP';
    }

    if (otp.length !== 4) {
      return 'OTP should be exactly 4 digits';
    }

    if (!/^\d{4}$/.test(otp)) {
      return 'OTP should only contain digits';
    }

    return '';
  };
  const resetForm = () => {
    validation.resetForm();
    registerValidation.resetForm();
    setOtp('');
    toggleSidebar();
    setShowOTPInput(false);
    setActiveSection('login');
  };

  const handleBackButton = () => {
    setActiveSection('login');
  }
  const handleResendClick = () => {
    validation.handleSubmit();
  };

  return (
    <>
      {activeSection === 'login' && (
        <LoginFormComponent
          validation={validation}
          toggleSidebar={toggleSidebar}
          handleSectionChange={handleSectionChange}
          isOpen={isOpen}
          setShowOTPInput={setShowOTPInput}
          handleVerifyClick={handleVerifyOtp}
          otpError={otpError}
          showOTPInput={showOTPInput}
          otp={otp}
          setOtp={setOtp}
          handleOtpChange={handleOtpChange}
          handleEditClick={handleEditClick}
          activeSection={activeSection}
          isLoading={isLoading}
          otpLoading={otpLoading}
          resetForm={resetForm}
        />
      )}

      {activeSection === 'register' && (
        <RegisterFormComponent
          toggleSidebar={toggleSidebar}
          handleSectionChange={handleSectionChange}
          isOpen={isOpen}
          setShowOTPInput={setShowOTPInput}
          handleVerifyClick={handleVerifyOtp}
          showOTPInput={showOTPInput}
          otp={otp}
          setOtp={setOtp}
          otpError={otpError}
          handleOtpChange={handleOtpChange}
          handleEditClick={handleEditClick}
          activeSection={activeSection}
          registerValidation={registerValidation}
          registerLoading={registerLoading}
          otpLoading={otpLoading}
          resetForm={resetForm}
          handleBackButton={handleBackButton}
          handleResendClick={handleResendClick}
        />
      )}
      <AddDeliveryLocationModal toggleLocationModal={toggleLocationModal} isOpen={isLocationModalOpen} />
    </>
  );
};

export default AuthSidebar;
