import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import config from '../../config';
import LoadingButton from 'components/buttons/LoadingButton';
import { useAddress } from 'context/AddressContext';
import { MdOutlineArrowOutward } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { createAddress, getStreetAddressFromLatLng } from '../../redux/reducer/home/homeAPI';
import { useNavigate } from 'react-router-dom';
import CustomInput from 'components/FormInput/customInput';
import { Toast } from 'service/toast';

const Marker = () => (
  <div className="marker-ripple-container">
    <div className="marker" />
  </div>
);

const addressTypes = [
  {
    label: 'Home',
    value: 1
  },
  {
    label: 'Work',
    value: 2
  },
  {
    label: 'Other',
    value: 3
  },
]

const bangaloreCoordinates = [
  { lat: 13.0846, lng: 77.5750 },
  { lat: 13.0101, lng: 77.5115 },
  { lat: 12.9101, lng: 77.5050 },
  { lat: 12.8711, lng: 77.5960 },
  { lat: 12.8741, lng: 77.7013 },
  { lat: 12.9401, lng: 77.7541 },
  { lat: 13.0201, lng: 77.7501 },
];

const MapComponent = ({ userLocation, toggleLocationModal }) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { updateSelectedAddress } = useAddress();
  const [customerDetails, setCustomerDetails] = useState({

  })
  const [isGoogleMapLoading, setMapLoading] = useState(false);

  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const [isServiceable, setIsServiceable] = useState(true)
  const [fullAddress, setFullAddress] = useState('');
  const [isFetchingAddress, setIsFetchingAddress] = useState(false)
  const [isErrorInGmap, setErrorInGmap] = useState(false)
  const [selectedAddressType, setSelectedAddressType] = useState(1);

  useEffect(() => {
    if (userLocation != null) {
      setMarkerPosition(userLocation)
    }
  }, [userLocation])

  const isPointInsideServicableArea = (coordinates, sv) => {
    const x = coordinates.lat, y = coordinates.lng;

    let inside = false;
    for (let i = 0, j = sv.length - 1; i < sv.length; j = i++) {
      const xi = sv[i].lat, yi = sv[i].lng;
      const xj = sv[j].lat, yj = sv[j].lng;

      const intersect = ((yi > y) !== (yj > y)) &&
        (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }

    return inside;
  }
  const handleMarkerChange = ({ center }) => {
    const isInside = isPointInsideServicableArea(center, bangaloreCoordinates)
    if (isInside) {
      setIsServiceable(true)
      setMarkerPosition({ ...markerPosition, lat: center.lat, lng: center.lng });
      // fetchAddress(center.lat, center.lng)
    } else {
      setIsServiceable(false)
    }

  };

  // const fetchAddress = async (lat, lng) => {
  //   try {
  //     setIsFetchingAddress(true);
  //     const params = {
  //       slug: `?lat=${lat}&lng=${lng}`
  //     }
  //     const response = await dispatch(getStreetAddressFromLatLng(params));
  //     if (response.payload.status === 200) {
  //       const address = response?.payload?.data?.street;
  //       setFullAddress(address);
  //     } else {
  //       setFullAddress('')
  //     }
  //   } catch (error) {
  //     setErrorInGmap(true)
  //   } finally {
  //     setIsFetchingAddress(false);
  //   }
  // };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      street: (customerDetails && customerDetails.street) || '',
      landmark: (customerDetails && customerDetails.landmark) || '',
      othersType: (customerDetails && customerDetails.othersType) || '',
      // postalCode: '',
    },
    validationSchema: Yup.object({
      street: Yup.string().required('Please Enter Your Street'),
      landmark: Yup.string().required('Please Enter Your Landmark'),
      //  postalCode: Yup.string()
      // .required('Please Enter Your Postal Code')
      // .matches(/^\d{6}$/, 'Postal Code must be a 6-digit number'),
    }),
    onSubmit: async (values) => {
      if (!userLocation) {
        Toast({ type: 'error', message: 'please choose location from map' })
        return;
      }
      const token = localStorage.getItem('token');
      if (token != null) {
        const obj = {
          ...values,
          type: selectedAddressType,
          othersType: selectedAddressType === 3 ? values.othersType : undefined,
          location: {
            type: 'Point',
            coordinates: [markerPosition.lng, markerPosition.lat],
          },
          isDefault: true,
        };
        try {
          setMapLoading(true);
          const response = await dispatch(createAddress(obj));
          if(response?.payload?.status === 200 && response?.payload?.data) {
            const res = response?.payload?.data
            const updatedAddress = {
              lat: res?.currentAddress?.location?.coordinates[1],
              lng: res?.currentAddress?.location?.coordinates[0],
              street: res?.currentAddress?.street,
              type: res?.currentAddress?.type,
              _id: res?.currentAddress?._id
            }
            updateSelectedAddress(updatedAddress)
            validation.resetForm();
            setSelectedAddressType('');
            toggleLocationModal()
          }
        } catch (error) {
          console.error('Error occurred during user registration:', error);
        } finally {
          setMapLoading(false);
        }
      } else {
        const dataToSet = {
          lat: markerPosition.lat,
          lng: markerPosition.lng,
          street: values.street,
          landmark: values.landmark,
          type: selectedAddressType,
          othersType: selectedAddressType === 3 ? values.othersType : ''
        }
        updateSelectedAddress(dataToSet);
        toggleLocationModal()
      }
    }
  });


  const drawPolygon = (map, maps) => {
    new maps.Polygon({
      paths: bangaloreCoordinates,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FFFFFF',
      fillOpacity: 0.25,
      map,
    });
  };
  return (
    <div className="map_location_form">
      <div className='secondary_bg rounded overflow-hidden gmap_container'>
        {markerPosition.lat !== 0 && markerPosition.lng !== 0 &&
          <div className='google_map' ref={mapRef}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: config.googleMapApiKey }}
              defaultCenter={markerPosition}
              center={markerPosition}
              defaultZoom={18}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => drawPolygon(map, maps)}
              onChange={handleMarkerChange}
            />
            <Marker
              lat={markerPosition.lat}
              lng={markerPosition.lng}
            />
          </div>}
      </div>
      <div className='gmap_address'>
        {isServiceable ?
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div >
              <CustomInput
                placeholder='Type Your Street Address'
                name='street'
                type='text'
                label='Door No / Street'
                labelClassName='col-form-label'
                validation={validation}
              />
            </div>

            <div >
              <CustomInput
                placeholder='Landmark'
                name='landmark'
                type='text'
                label='Landmark'
                labelClassName='col-form-label'
                validation={validation}
              />
            </div>

            <div>
              <label className="form-label">Address Label</label>
              <div className="address_types">
                {addressTypes.map(type => (
                  <div className={`address_type_btn ${selectedAddressType === type.value ? "selected_address_type_btn" : ""}`} onClick={() => setSelectedAddressType(type.value)} >{type.label}</div>
                ))}
              </div>
              {selectedAddressType === 'OTHER' && (
                <div className="col-12 pt-3">
                  <CustomInput
                    placeholder="Please Type..."
                    name='othersType'
                    type='text'
                    label='Name Your Address Label'
                    labelClassName='col-form-label'
                    validation={validation}
                  />
                </div>
              )}
            </div>

            <div className="save_address_wrapper ">
              <LoadingButton
                name="submit"
                type="submit"
                className="btn btn-primary d-block btn-hover-2 confirm_address_btn"
                text="Confirm Address"
              />
            </div>
          </form>
          :
          <div className='not_serviceable_map_container'>
              <p className='sit_tight'>Sit Tight! We’re Coming Soon to your location!</p>
              <div className='not_serviceable_group_text' onClick={() => handleMarkerChange({ center: { lat: 12.978844024227286, lng: 77.57115675313082 } })}>
                <p className='take_me_to'>Take me to Serviceable Area</p>
                <MdOutlineArrowOutward />
              </div>
          </div>
        }
      </div>
    </div>
  );
};

export default MapComponent;
