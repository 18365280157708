import React from 'react';
import {  Spinner } from 'reactstrap';

const LoadingButton = ({ loading, text, ...rest }) => {
  return (
    <button {...rest} disabled={loading}>
      {loading ? <Spinner size="sm" color="light" /> : <span>{text}</span>}
    </button>
  )
};

export default LoadingButton;
