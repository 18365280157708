import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { IoLocation, IoMail } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import Partner from '../../assets/icons/parnter.svg'
const Footer = () => {
    const navigate = useNavigate()
    return (
        <>
            <footer className="site-footer bg-dark" id="footer">
                <div className="footer-top">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-12 wow fadeInUp footer_col" data-wow-delay="0.4s">
                                <div>
                                    <img src={require('assets/images/new_ninans_logo.webp')} alt='Ninan' className='footer_logo' loading='lazy' />
                                    <h5 className='footer_tagliner'>The pinnacle of Gourmet Excellence</h5>
                                </div>
                                <div>
                                    <p className='fssai'>FSSAI : 11224334000237</p>
                                </div>
                                <div className="partner_with_us">
                                    <img src={Partner} loading='lazy'  />
                                    <p onClick={() => navigate('/franchise')}>Partner With Us</p>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-3 col-12 wow fadeInUp footer_col" data-wow-delay="0.5s">
                                <div className="widget widget_getintuch">
                                    <h5 className="footer-title">Explore</h5>
                                    <ul>
                                        <li><p onClick={() => navigate('/our-story')}><span>Our Story</span></p></li>
                                        <li><p onClick={() => navigate('/faq')}><span>FAQs</span></p></li>
                                        <li><p onClick={() => navigate('/support')}><span>Support</span></p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3 col-md-3 col-12 wow fadeInUp footer_col" data-wow-delay="0.6s">
                                <div className="widget widget_services">
                                    <h5 className="footer-title">Quick Connect</h5>
                                    <ul>
                                        <li>
                                            <a>
                                                <IoLocation className='footer_outlet_ic' />
                                                <div className='ft_address'>
                                                    <p>
                                                        Maple and Olive Foods,
                                                    </p>
                                                    <p>
                                                        3rd Floor, Om Towers, 33, opp to Dakshin cafe
                                                    </p>
                                                    <p>
                                                        RBI Layout, JP Nagar 7th Phase, 
                                                    </p>
                                                    <p>Bengaluru, Karnataka 560078</p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <IoMail className='footer_outlet_ic' />
                                                <p className='footer_outlet_txt'>vendors@mapleolives.com</p>
                                            </a>
                                            
                                        </li>
                                        <li>
                                            <a>
                                                <FaPhoneAlt className='footer_outlet_ic' />
                                                <p className='footer_outlet_txt'>+91 6364-874-557</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-12 wow fadeInUp footer_col" data-wow-delay="0.7s">
                                <div className="widget widget_services ">
                                    <h5 className="footer-title">Outlets</h5>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.swiggy.com/city/bangalore/ninans-steakhouse-jp-nagar-rest346294"
                                                target="_blank" rel="noreferrer"
                                            >
                                                <HiLocationMarker className='footer_outlet_ic' />
                                                <p className='footer_outlet_txt'>JP Nagar 7th phase</p>
                                            </a>
                                        </li>
                                        <li><a href="https://www.swiggy.com/city/bangalore/ninans-steakhouse-btm-rest953630" target="_blank" rel="noreferrer">
                                            <HiLocationMarker className='footer_outlet_ic' /><p className='footer_outlet_txt'>BTM Layout</p>
                                            </a>
                                        </li>
                                        <li><a href="https://www.swiggy.com/city/bangalore/ninans-steakhouse-bommanahalli-hsr-layout-rest606175" target="_blank" rel="noreferrer">
                                            <HiLocationMarker className='footer_outlet_ic' /><p className='footer_outlet_txt'>HSR Layout</p></a></li>

                                        <li><a href="https://www.swiggy.com/city/bangalore/ninans-steakhouse-mahadevapura-whitefield-rest774782 " target="_blank" rel="noreferrer">
                                            <HiLocationMarker className='footer_outlet_ic' /><p className='footer_outlet_txt'>Whitefield</p></a></li>
                                        <li><a href="https://www.swiggy.com/city/bangalore/ninans-steakhouse-indiranagar-rest965796 " target="_blank" rel="noreferrer">
                                            <HiLocationMarker className='footer_outlet_ic' /><p className='footer_outlet_txt'>Indhira Nagar</p></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="footer-bottom">
                        <div className="row">
                            <div className='col-xl-4'>
                                <p className='footer_sm_txts'>
                                    <span onClick={() => navigate('/privacy-policy')}>Privacy Policy | </span>
                                    <span onClick={() => navigate('/terms-and-conditions')}>Terms & Conditions</span>
                                </p>
                            </div>
                            <div className="col-xl-5 col-12 text-md-center">
                                <p>Copyright © 2024 Ninan's Burger Bar | Powered By <a href='https://iconicglobalsolution.com/' className="powered_by" target="_blank" rel='noreferrer'>Iconic Global Solution</a></p>
                            </div>
                            <div className='col-xl-3'>
                                <div className="social-icons">
                                    <a href="https://www.instagram.com/ninansburgerbar/" target="_blank" rel="noreferrer"><FaFacebookF className='footerIcon' /></a>
                                    <a href="https://www.instagram.com/ninansburger.official/" target="_blank" rel="noreferrer"><FaInstagram className='footerIcon' /></a>
                                    <a href="https://wa.me/6364874557?text=Hello%20there!" target="_blank" rel="noreferrer"><FaWhatsapp className='footerIcon' /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    )
}

export default Footer;