import React, { useState } from 'react';
import {
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Spinner,
  Button,
} from 'reactstrap';

const CustomInput = ({ type, validation, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const getInput = () => {
    switch (type) {
      case 'text':
        return (
          <div className={`pb-3 ${rest.formGroupClassName} text-left`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className='text-yellow'>&nbsp;( Optional )</span>
                ) : (
                  ''
                )}
              </Label>
            )}
            <Col>
              {rest?.showLeftIcon &&
                <InputGroup>
                  <InputGroupText>
                    {rest?.leftIcon}
                  </InputGroupText>
                </InputGroup>
              }
              <Input
                type='text'
                name={rest.name}
                maxLength={rest.maxLength}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={`${rest.name === 'mobileNumber' ? 'mobile_number_input' : ""} ${rest.valueClassName} ${validation.touched[rest.name] && validation.errors[rest.name]
                  ? 'was-invalid'
                  : ''
                  }`}
                placeholder={rest.placeholder}
                value={validation.values[rest.name] || ''}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
                disabled={rest.disabled}
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type='invalid'>
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </div>
        );
      case 'password':
        return (
          <div className={`pb-3 form-group`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className='text-yellow'>&nbsp;( Optional )</span>
                ) : (
                  ''
                )}
              </Label>
            )}
            <Col>
              <Input
                type={showPassword ? 'text' : 'password'} // Toggle input type
                name={rest.name}
                maxLength={rest.maxLength}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={rest.valueClassName}
                placeholder={rest.placeholder}
                value={validation.values[rest.name] || ''}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type='invalid'>
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
              {/* Toggle button for show/hide password */}

              <Button
                onClick={togglePasswordVisibility}
                className={`mt-2 ${validation.touched[rest.name] && validation.errors[rest.name]
                  ? 'validation'
                  : 'without_validation'
                  }`}
                color='secondary'
              >
                <i
                  className={`passowrd_icon ${showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}`}
                ></i>
              </Button>
            </Col>
          </div>
        );
      case 'textarea':
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className='text-yellow'>&nbsp;( Optional )</span>
                ) : (
                  ''
                )}
              </Label>
            )}
            <Col>
              <Input
                type='textarea'
                name={rest.name}
                maxLength={rest.maxLength}
                placeholder={rest.placeholder}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[rest.name] || ''}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type='invalid'>
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </div>
        );
      case 'two-way-switch':
        return (
          <div className={rest.formGroupClassName}>
            {rest.label && (
              <Label className={rest.labelClassName}>{rest.label}</Label>
            )}
            <Col>
              <div className=' form-switch form-switch-sm two-way-switch'>
                {rest.leftLabel && <Label>{rest.leftLabel}</Label>}
                <Input
                  type='checkbox'
                  name={rest.name}
                  className='form-check-input'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values[rest.name] || ''}
                  invalid={
                    validation.touched[rest.name] &&
                      validation.errors[rest.name]
                      ? true
                      : false
                  }
                />

                {validation.touched[rest.name] &&
                  validation.errors[rest.name] ? (
                  <FormFeedback type='invalid'>
                    {validation.errors[rest.name]}
                  </FormFeedback>
                ) : null}
                {rest.rightLabel && <Label>{rest.rightLabel}</Label>}
              </div>
            </Col>
            <strong className='text-danger small'>
              {rest.error ? rest.error[0] : ''}
            </strong>
          </div>
        );
      case 'button':
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            <Label className={rest.labelClassName}>{rest.label}</Label>
            <Col>
              {rest.loading ? (
                <div className='primary_btn'>
                  <Spinner size='lg' color='#fff' />
                </div>
              ) : (
                <button type='submit' size='lg' className='px-4 primary_btn'>
                  {rest.btnLabel}
                </button>
              )}
            </Col>
          </div>
        );
      case 'select':
        return (
          <FormGroup className={rest.className}>
            <Label for={rest.name} className={rest.labelClassName}>
              {rest.label}
              {rest.isOptional ? (
                <span className='text-yellow'>&nbsp;( Optional )</span>
              ) : (
                ''
              )}
            </Label>
            <div className='custom-select-caret'>
              <Input
                disabled={rest.disabled}
                onChange={validation.handleChange}
                type='select'
                className='cursor-pointer'
                value={validation.values[rest.name] || ''}
                name={rest.name}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              >
                {rest.defaultSelect ? <option value=''>Select</option> : ''}
                {/* 
                                {
                                    rest.options.map((data, i) => <option key={i} value={data.value} disabled={data.disabled}>{data.label}</option>)
                                } */}
                {rest.options && rest.options.length > 0
                  ? rest.options.map((data, i) => (
                    <option
                      key={i}
                      value={data.value}
                      disabled={data.disabled}
                    >
                      {data.label}
                    </option>
                  ))
                  : null}
              </Input>

              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type='invalid'>
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </div>
            {rest.optionalRightLabel ? (
              <Label className='ml-2'>{rest.optionalRightLabel}</Label>
            ) : (
              ''
            )}
          </FormGroup>
        );
      case 'group':
        return (
          <FormGroup className={rest.className}>
            <Label for={rest.name} className={rest.labelClassName}>
              {rest.label}
              {rest.isOptional ? (
                <span className='text-yellow'>&nbsp;( Optional )</span>
              ) : null}
            </Label>
            <div className='custom-select-caret'>
              <Input
                disabled={rest.disabled}
                onChange={validation.handleChange}
                type='select'
                className='cursor-pointer'
                value={validation.values[rest.name] || ''}
                name={rest.name}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              >
                {rest.defaultSelect ? <option value=''>Select</option> : null}
                {rest.options.map((data, i) => (
                  <optgroup key={i} label={data.label}>
                    {data.options.map((nestedData, j) => (
                      <option
                        key={`${i}-${j}`}
                        value={nestedData.value}
                        disabled={nestedData.disabled}
                      >
                        {nestedData.label}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Input>

              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type='invalid'>
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </div>
            {rest.optionalRightLabel ? (
              <Label className='ml-2'>{rest.optionalRightLabel}</Label>
            ) : null}
          </FormGroup>
        );

      default:
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className='text-yellow'>&nbsp;( Optional )</span>
                ) : (
                  ''
                )}
              </Label>
            )}
            <Col>
              <Input
                type='text'
                name={rest.name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[rest.name] || ''}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type='invalid'>
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </div>
        );
    }
  };
  return getInput();
};

export default CustomInput;
