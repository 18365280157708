export const AuthApi = {
  loginApi: {
    url: '/customer/login',
    method: 'post',
    get api() {
      return this.url
    },
  },
  registerApi: {
    url: '/customer/register',
    method: 'post',
    get api() {
      return this.url
    },
  },
  verifyOtpApi: {
    url: '/customer/verify/otp',
    method: 'post',
    get api() {
      return this.url
    },

  },
  resendOtpApi: {
    url: '/customer/resend/otp',
    method: 'post',
    get api() {
      return this.url
    }
  }
};
export const HomepageApis = {
  offerListApi: {
    url: '/homepage/offer/list',
    method: 'get',
    get api() {
      return this.url
    }
  },
  bannerListApi: {
    url: '/homepage/banner/list',
    method: 'get',
    get api() {
      return this.url
    }
  },
  // category
  categoryListApi: {
    url: '/homepage/category/list',
    method: 'get',
    get api() {
      return this.url
    },
  },
  homeProductsListApi: {
    url: '/homepage/category/products/list',
    method: 'post',
    get api() {
      return this.url
    },
  },
  getProductsByCategoryId: {
    url: '/homepage/products',
    method: 'post',
    get api() {
      return this.url
    },
  },
  getProductByIdApi: {
    url: '/homepage/product/detail',
    method: 'get',
    get api() {
      return this.url + this.params.slug
    },
  },
  getOtherProductsApi: {
    url: '/homepage/other/products',
    method: 'get',
    get api() {
      return this.url
    },
  }
}

export const UpadteCustomer = {
  customerDetails: {
    url: '/customer/update',
    method: 'put',
    get api() {
      return this.url
    },
  }
}
export const GetCustomerDetails = {
  customerDetails: {
    url: '/customer/detail',
    method: 'get',
    get api() {
      return this.url
    },
  }
};
export const GetAddressDetails = {
  addressDetails: {
    url: '/address/list',
    method: 'get',
    get api() {
      return this.url
    },
  }
};
export const CreateAddress = {
  address: {
    url: '/address/create',
    method: 'post',
    get api() {
      return this.url
    },
  }
};
export const GetAddressById = {
  address: {
    url: '/address/detail',
    method: 'get',
    get api() {
      return this.url + this.body.slug
    },
  }
};
export const UpadateAddress = {
  editAddress: {
    url: '/address/edit',
    method: 'put',
    get api() {
      return this.url
    },
  },
  updateDefaultAddressApi: {
    url: '/address/update/status',
    method: 'put',
    get api() {
      return this.url + this.params.slug
    },
  },
};
export const DeleteAddress = {
  address: {
    url: '/address/delete',
    method: 'delete',
    get api() {
      return this.url + this.body.slug
    },
  }
};

export const getAddressFromLatLng = {
  addressFromLatLng: {
    url: '/address/geocode',
    method: 'get',
    get api() {
      return this.url + this.params.slug
    },
  }
};

export const getDirectionsFromAndToLatLng = {
  directionsFromAndToLatLng: {
    url: '/address/directions',
    method: 'post',
    get api() {
      return this.url
    },
  }
};

// cart Api
export const Cart = {
  cartListApi: {
    url: '/order/cart',
    method: 'post',
    get api() {
      return this.url
    },
  }
};
// orders api
export const orders = {
  orderCreate: {
    url: '/order/create',
    method: 'post',
    get api() {
      return this.url
    },
  },
  paymentStatusApi: {
    url: '/order/payment/status',
    method: 'get',
    get api() {
      return this.url + this.params.slug
    },
  }
};


export const PastOrders = {
  ordersList: {
    url: '/order/history',
    method: 'get',
    get api() {
      return this.url
    },
  }
};


export const updateAddressApi = {
  url: '/address/edit',
  method: 'put',
  get api() {
    return this.url
  },
};

export const TrackOrder = {
  orderTrack: {
    url: '/order/track',
    method: 'get',
    get api() {
      return this.url + this.params.slug
    },
  }
};

export const CancelOrder = {
  cancelTheOrder: {
    url: '/order/cancel',
    method: 'get',
    get api() {
      return this.url + this.params.slug
    },
  }
};