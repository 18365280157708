import PropTypes from 'prop-types';
import './avatar.scss';

export const Avatar = ({ firstName, lastName, profileUrl, isSmall, isBig }) => {
  const firstLetterValue = firstName.toUpperCase().charCodeAt(0);
  const secondLetterValue = lastName?.toUpperCase().charCodeAt(0);

  const firstLetter = firstName?.charAt(0);
  const secondLetter = lastName?.charAt(0);



  const sizeClassName = isSmall ? 'Small_Avatar' : isBig ? 'Big_Avatar' : '';

  return (
    <div className={`Avatar_Container ${sizeClassName}`}>
      {profileUrl ? (
        <img src={profileUrl} alt={`${firstName}`}  className='avatar_image'/>
      ) : (
        <div  className="Avatar">
          {firstLetter}
          {secondLetter}
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileUrl: PropTypes.string,
  isSmall: PropTypes.bool,
  isBig: PropTypes.bool,
};

Avatar.defaultProps = {
  firstName: '',
  lastName: '',
  profileUrl: '',
  isSmall: false,
  isBig: false,
};
