const routers = [
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/home/index.jsx',
    name: 'Home',
    path: '/',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/categories',
    name: 'Categories',
    path: '/categories',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/productDetail/index.jsx',
    name: 'ProductDetail',
    path: '/product/detail',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/profile/index.jsx',
    name: 'Profile',
    path: '/profile',
    auth: true,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/cart/index.jsx',
    name: 'Cart',
    path: '/checkout',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/orderTracking/index.jsx',
    name: 'OrderTracking',
    path: '/profile/order/track',
    auth: true,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/profile/mobileLayout/orders.js',
    name: 'OrdersMobileLayout',
    path: '/profile/orders',
    auth: true,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/profile/mobileLayout/address.js',
    name: 'CustomerAddressesMobileLayout',
    path: '/profile/address',
    auth: true,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/about.js',
    name: 'About',
    path: '/our-story',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/faq.js',
    name: 'Faq',
    path: '/faq',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/franchise.js',
    name: 'Franchise',
    path: '/franchise',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/help.js',
    name: 'Help',
    path: '/support',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/team.js',
    name: 'Team',
    path: '/our-team',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/terms.js',
    name: 'Terms',
    path: '/terms-and-conditions',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/testimonials.js',
    name: 'Testimonials',
    path: '/testimonials',
    auth: false,
    exact: true,
  },
  {
    component: 'MainLayout',
    componentLinkPath: 'pages/staticPages/privacy.js',
    name: 'Privacy',
    path: '/privacy-policy',
    auth: false,
    exact: true,
  },
];

export default routers;
