import { CartContext } from 'context/CartContext';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'

const LogoutPopup = ({ ismodelOpen, toggleModel }) => {
    const { clearCart } = useContext(CartContext)

    const navigate = useNavigate()
    
    const logout = () => {
        localStorage.clear()
        clearCart()
        navigate('/');
        window.location.reload();
    };

    return (
        <>
            <div 
                className={`modal fade ${ismodelOpen ? 'show' : ''}`} 
                id="exampleModalToggle2" 
                aria-hidden="true" 
                aria-labelledby="exampleModalToggleLabel2"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered login-popup-main">
                    <div className="modal-content border-0 overflow-hidden rounded">
                        <div className="modal-body">
                            <div className="row g-0">
                                <div className="d-none d-md-flex col-md-4 col-lg-4 bg-image1">
                                    <img src={require('../../assets/images/logout.jpg')} alt='logout' />
                                </div>
                                <div className="col-md-8 col-lg-8">
                                    <div className="logout_main">
                                        <div className='logout_content'>
                                            <h5 className="mb-2 fw-bold text-center logout_popup_title">Hungry for more? 🍽️ </h5>
                                            <p className="text-muted mb-0 logout_popup_subtitle">
                                                Don't miss out on our mouthwatering delights! Log back in soon for another tasty adventure. 🚀
                                            </p>
                                        </div>
                                        <div className='logout_btn_wrapper'>
                                            <button className="btn    text-uppercase w-100  logout_cancel_btn"
                                                data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={toggleModel}>Cancel </button>
                                            <button className="btn btn-primary  text-uppercase w-100   login_logout_btn"
                                                data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" onClick={logout}>Logout </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {ismodelOpen && <div className={`offcanvas-backdrop  ${ismodelOpen ? 'show' : 'fade'}`} onClick={toggleModel}></div>
            }
        </>
    )
}

export default LogoutPopup;