import { useState, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "components/sidebar";
import { CartContext } from "context/CartContext";

const MobileMenu = () => {
    const isMobile = window.innerWidth <= 992;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { cartItems } = useContext(CartContext)

    const totalQuantity = useMemo(() => cartItems.reduce((total, item) => total + item.quantity, 0), [cartItems]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const menuItems = [
        { path: "/", label: "Home", icon: "flaticon-chef" },
        { path: "/categories", label: "Categories", icon: "flaticon-burger-1" },
        { path: "/checkout", label: "Cart", icon: "flaticon-shopping-cart", showBadge: true },
        { path: "/profile", label: "Account", icon: "flaticon-user" },
    ];

    const tryToNavigate = (path) => {
        const token = localStorage.getItem('token');
        if(path === '/checkout'){
            if(token != null){
                navigate(path)
            } else {
                navigate('/profile')
            }
        } else {
            navigate(path)
        }
    }

    return (
        <>
            {isMobile && (
                <div className="d-lg-none">
                    <div className="w-100 mobile-nav-bottom position-fixed d-flex align-items-center justify-content-around shadow-sm">
                        {menuItems.map((item) => (
                            <button
                                key={item.path}
                                className={`mobile-menu ${location.pathname === item.path ? "active" : ""}`}
                                onClick={() => tryToNavigate(item.path)}
                            >
                                <i className={`${item.icon} ${location.pathname === item.path ? "active" : ""}`}></i> {item.label}
                                {item.showBadge && totalQuantity > 0 && (
                                    <span className="mob_cart_qty">{totalQuantity}</span>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            )}
            {isSidebarOpen &&
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            }
        </>
    );
};

export default MobileMenu;
