import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { ternaryCondition } from 'helpers/utis';
import { Avatar } from './Avatar';
import { FaUserCog } from "react-icons/fa";
import LogoutPopup from 'components/popup/LogoutPopup';
import { AuthContext } from 'context/AuthContext';


const ProfileDropdown = () => {
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState(false);
  const [ismodelOpen, setIsModalOpen] = useState(false);

  const toggleModel = () => {
    setIsModalOpen(!ismodelOpen);
  };

  const handleTitleClick = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownElement = document.getElementById('profileDropdown');

      if (dropdownElement && !dropdownElement.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getFirstName = () => {
    return userData.name ? userData.name.charAt(0).toUpperCase() + userData.name.slice(1) : ''
  }
  const getLastName = () => {
    const lastName = userData.name?.split(' ')
    return lastName ? ternaryCondition(lastName?.length > 1, lastName[lastName.length - 1], '') : ''
  }

  const profile = () => {
    navigate('/profile')
  }
  return (
    <>
      <ul className='navbar-nav header-right cart-item '>
        <li
          id='profileDropdown'
          className={`nav-item dropdown header-profile ${showDropdown ? 'show' : ''
            }`}
        >
          <div
            className='nav-link'
            data-bs-toggle='dropdown'
            onClick={handleTitleClick}
          >
            <div className='header-info'>
              <Avatar firstName={getFirstName()} lastName={getLastName()} />
              <span className='header_user_info'>
                {userData.name}
              </span>
            </div>
          </div>
          <div
            className={`dropdown-menu dropdown-menu-right profile_dropdown${showDropdown ? 'show' : ''
              }`}
          >
            <div className='dropdown-item ai-icon' onClick={profile}>
              <FaUserCog />
              <span className='ml-2'>Profile </span>
            </div>
            <div className='dropdown-item ai-icon' onClick={toggleModel}>
              <svg
                id='icon-logout'
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                <polyline points='16 17 21 12 16 7'></polyline>
                <line x1='21' y1='12' x2='9' y2='12'></line>
              </svg>
              <span className='ml-2'>Logout </span>
            </div>
          </div>
        </li>
      </ul>
      <LogoutPopup toggleModel={toggleModel} ismodelOpen={ismodelOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default ProfileDropdown;
