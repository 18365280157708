// RegisterFormComponent.jsx
import React, { useState, useEffect } from 'react';
import CustomInput from '../FormInput/customInput';
import { FaArrowLeftLong } from "react-icons/fa6";
import OtpInput from 'react-otp-input';
import LoadingButton from 'components/buttons/LoadingButton';
import flag from 'assets/icons/svg_icons/indiaflag.svg'

const RegisterFormComponent = ({ handleResendClick, registerValidation, handleSectionChange, isOpen, handleVerifyClick, showOTPInput, otp, handleOtpChange, handleEditClick, otpError, registerLoading, otpLoading, resetForm, handleBackButton }) => {

  const [defaultMobileNumber, setDefaultMobileNumber] = useState('');

  useEffect(() => {
    const storedMobileNumber = localStorage.getItem('loginnumber');
    if (storedMobileNumber) {
      setDefaultMobileNumber(storedMobileNumber);
    }
  }, []);

  return (
    <>
      <div className={`offcanvas offcanvas-end d-lg-block d-none ${isOpen ? 'show' : ''}`} id="offcanvasRegister">
        <div className="offcanvas-body">
          <button type="button" className={`btn-close`} onClick={handleBackButton}  >
            <FaArrowLeftLong />
          </button>
          <div className="offcanvas-form">
            <div className="login-head">
              <div>
                <h4 className="login_title">{showOTPInput ? 'Verify' : 'Create Account'}</h4>
                <p className='register-subtitle'>{showOTPInput ? "We've sent an OTP to your Mobile Number." : 'Create an account with your Mobile Number'}</p>
              </div>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                registerValidation.handleSubmit();
                return false;

              }}
            >
              {showOTPInput ? null : (
                <div>
                  <div >
                    <CustomInput
                      placeholder='Name'
                      name='name'
                      type='text'
                      label='Name'
                      value={registerValidation.values.name || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>

                  <div >
                    <CustomInput
                      placeholder='Email '
                      name='emailId'
                      type='text'
                      label='Email'
                      value={registerValidation.values.emailId || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>
                </div>
              )}
              <div className='mobile_div'>
                <CustomInput
                  placeholder='XXX-XXX-XXXX'
                  name='mobileNumber'
                  type='text'
                  label='Mobile Number'
                  showLeftIcon={true}
                  leftIcon={
                    <>
                      <img src={flag} alt="India Flag" />
                      <p>+91 </p>
                    </>
                  }
                  value={defaultMobileNumber || registerValidation.values.mobileNumber || ''}
                  labelClassName='col-form-label'
                  validation={registerValidation}
                />
                {showOTPInput &&
                  <i
                    className='fas fa-edit edit-icon'
                    onClick={handleEditClick}
                  ></i>
                }


              </div>
              {showOTPInput &&
                <div className='otp-input-container'>
                  <label className="otp-label">OTP</label>
                  <div>
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4}
                      renderSeparator={<span> - </span>}
                      renderInput={(props) => (
                        <input {...props} type='text' min='0' />
                      )}
                      containerStyle='otpInputContainer'
                      shouldAutoFocus
                      inputStyle={{
                        padding: '0px',
                      }}
                    />

                  </div>
                  {otpError && <p className='feedback'>{otpError}</p>}
                  <LoadingButton
                    name="submit"
                    type="submit"
                    className="btn btn-primary w-100 d-block btn-hover-2"
                    text="Verify Otp"
                    onClick={handleVerifyClick}
                    loading={otpLoading}
                  />
                  <div className='resend_container'>
                    <div onClick={handleResendClick} className="btn btn-link resend_btn">Resend OTP</div>
                  </div>
                </div>
              }

              {!showOTPInput &&
                <div className='text-center'>
                  <LoadingButton
                    name="Submit"
                    type="submit"
                    className="btn btn-primary w-100 d-block btn-hover-2"
                    text="Register"
                    // onClick={handleVerifyClick}
                    loading={registerLoading}
                  />

                </div>
              }
            </form>
            <p className="text-center m-t30 isNew">Have an account? {' '}
              <span role="button" aria-controls="offcanvasLogin" className={`toggle-button`} onClick={() => handleSectionChange('login')} >Login</span>
            </p>
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className={`offcanvas offcanvas-end d-lg-none ${isOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasLoginMobile">
        <div className="offcanvas-body">
          <button type="button" className={`btn-close`} onClick={handleBackButton}  >
            <FaArrowLeftLong />
          </button>
          <div className="offcanvas-form">
            <div className="login-head">
              <div>
                <h4 className="login_title">{showOTPInput ? 'Verify' : 'Create Account'}</h4>
                <p className='register-subtitle'>{showOTPInput ? "We've sent an OTP to your phone number." : 'Create an account with the new phone number'}</p>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                registerValidation.handleSubmit();
                return false;

              }}
            >
              {showOTPInput ? null : (
                <div>
                  <div >
                    <CustomInput
                      placeholder='Name'
                      name='name'
                      type='text'
                      label='Name'
                      value={registerValidation.values.name || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>

                  <div >
                    <CustomInput
                      placeholder='Email '
                      name='emailId'
                      type='text'
                      label='Email'
                      value={registerValidation.values.emailId || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>
                </div>
              )}
              <div className='mobile_div'>
                <CustomInput
                  placeholder='XXX-XXX-XXXX'
                  name='mobileNumber'
                  type='text'
                  label='Mobile Number'
                  showLeftIcon={true}
                  leftIcon={
                    <>
                      <img src={flag} alt="India Flag" />
                      <p>+91 </p>
                    </>
                  }
                  value={defaultMobileNumber || registerValidation.values.mobileNumber || ''}
                  labelClassName='col-form-label'
                  validation={registerValidation}
                />
                {showOTPInput &&
                  <i
                    className='fas fa-edit edit-icon'
                    onClick={handleEditClick}
                  ></i>
                }


              </div>
              {showOTPInput &&
                <div className='otp-input-container'>
                  <label className="otp-label">OTP</label>
                  <div>
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4}
                      renderSeparator={<span> - </span>}
                      renderInput={(props) => (
                        <input {...props} type='text' min='0' />
                      )}
                      containerStyle='otpInputContainer'
                      shouldAutoFocus
                      inputStyle={{
                        padding: '0px',
                      }}
                    />

                  </div>
                  {otpError && <p className='feedback'>{otpError}</p>}
                  <LoadingButton
                    name="submit"
                    type="submit"
                    className="btn btn-primary w-100 d-block btn-hover-2"
                    text="Verify Otp"
                    onClick={handleVerifyClick}
                    loading={otpLoading}
                  />
                  <div className='resend_container'>
                    <div onClick={handleResendClick} className="btn btn-link resend_btn">Resend OTP</div>
                  </div>
                </div>
              }

              {!showOTPInput &&
                <div className='text-center'>
                  <LoadingButton
                    name="Submit"
                    type="submit"
                    className="btn btn-primary w-100 d-block btn-hover-2"
                    text="Register"
                    // onClick={handleVerifyClick}
                    loading={registerLoading}
                  />

                </div>
              }
            </form>
            <p className="text-center m-t30 isNew">Have an account? {' '}
              <span role="button" aria-controls="offcanvasLogin" className={`toggle-button`} onClick={() => handleSectionChange('login')} >Login</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterFormComponent;

