/* eslint-disable import/no-cycle */
import {
  AuthApi,
  CreateAddress,
  GetCustomerDetails,
  GetAddressDetails,
  GetAddressById,
  DeleteAddress,
  UpadateAddress,
  PastOrders,
  UpadteCustomer,
  TrackOrder,
  HomepageApis,
  Cart,
  updateAddressApi,
  //  GEO ADDRESS
  getAddressFromLatLng,
  getDirectionsFromAndToLatLng,
  CancelOrder,
  orders
} from '../../../service/apiVariables';

import { createAsyncThunk } from '@reduxjs/toolkit';

const namespace = 'home';

// AUTHENTICATION APIS
export const login = createAsyncThunk(
  `${namespace}/userlogin`,
  async (payload, { extra: { api, Toast } }) => {

    const { loginApi } = AuthApi
    loginApi.body = payload
    try {
      const data = await api({
        ...loginApi,
      })
      if (data) {
        Toast({
          type: 'success',
          message: "Success"
        })
      }
      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const register = createAsyncThunk(
  `${namespace}/register`,
  async (payload, { extra: { api, Toast } }) => {

    const { registerApi } = AuthApi
    registerApi.body = payload
    try {
      const data = await api({
        ...registerApi,
      })
      if (data) {
        Toast({
          type: 'success',
          message: "Success"
        })

      }
      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const resendOtp = createAsyncThunk(
  `${namespace}/resend`,
  async (payload, { extra: { api, Toast } }) => {

    const { resendOtpApi } = AuthApi
    resendOtpApi.body = payload
    try {
      const { data, message } = await api({
        ...resendOtpApi,
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })

      }
      return { data, message }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const verifyOtp = createAsyncThunk(
  `${namespace}/verify`,
  async (payload, { extra: { api, Toast } }) => {

    const { verifyOtpApi } = AuthApi
    verifyOtpApi.body = payload
    try {
      const data = await api({
        ...verifyOtpApi,
      })
      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
      return error
    }
  }
)

// HOMEPAGE APIS
export const getBannerList = createAsyncThunk(
  `${namespace}/banner`,
  async (payload, { extra: { api, Toast } }) => {

    const { bannerListApi } = HomepageApis

    bannerListApi.body = payload
    try {
      const { data } = await api({
        ...bannerListApi
      })
      return { data }

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const getOfferContent = createAsyncThunk(
  `${namespace}/offerListApi`,
  async (payload, { extra: { api, Toast } }) => {
    const { offerListApi } = HomepageApis
    try {
      const response = await api({
        ...offerListApi
      })
      return response

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const getCategoryList = createAsyncThunk(
  `${namespace}/categoryListApi`,
  async (payload, { extra: { api, Toast } }) => {
    const { categoryListApi } = HomepageApis
    try {
      const response = await api({
        ...categoryListApi
      })
      return response

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const getCategoriesWithProducts = createAsyncThunk(
  `${namespace}/getCategoriesWithProducts`,
  async (payload, { extra: { api, Toast } }) => {

    const { homeProductsListApi } = HomepageApis
    homeProductsListApi.body = payload
    try {
      const data = await api({
        ...homeProductsListApi
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

// CATEGORIES PAGE APIS
export const getProductsByCategory = createAsyncThunk(
  `${namespace}/allproducts`,
  async (payload, { extra: { api, Toast } }) => {

    const { getProductsByCategoryId } = HomepageApis

    getProductsByCategoryId.body = payload

    try {
      const data  = await api({
        ...getProductsByCategoryId
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

// PRODUCT DETAIL PAGE API
export const getProductById = createAsyncThunk(
  `${namespace}/getById`,
  async (payload, { extra: { api, Toast } }) => {

    const { getProductByIdApi } = HomepageApis
    getProductByIdApi.params = payload
    try {
      const data = await api({
        ...getProductByIdApi
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

// OTHER PRODUCTS LIST API
export const getOtherProducts = createAsyncThunk(
  `${namespace}/getOtherProductsApi`,
  async (payload, { extra: { api, Toast } }) => {

    const { getOtherProductsApi } = HomepageApis
    getOtherProductsApi.params = payload
    try {
      const data = await api({
        ...getOtherProductsApi
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

// ADDRESS APIS
export const getCustomerDetails = createAsyncThunk(
  `${namespace}/customerDetails`,
  async (payload, { extra: { api, Toast } }) => {

    const { customerDetails } = GetCustomerDetails

    customerDetails.body = payload
    try {
      const response = await api({
        ...customerDetails
      })
      return response

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const updateCustomer = createAsyncThunk(
  `${namespace}/customerDetails`,
  async (payload, { extra: { api, Toast } }) => {

    const { customerDetails } = UpadteCustomer

    customerDetails.body = payload
    try {
      const { data, message, status } = await api({
        ...customerDetails
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data, message, status }

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)
export const getCustomerAddressList = createAsyncThunk(
  `${namespace}/addressDetails`,
  async (payload, { extra: { api, Toast } }) => {

    const { addressDetails } = GetAddressDetails

    addressDetails.body = payload
    try {
      const response = await api({
        ...addressDetails
      })

      return response

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const createAddress = createAsyncThunk(
  `${namespace}/address`,
  async (payload, { extra: { api, Toast } }) => {

    const { address } = CreateAddress
    address.body = payload
    try {
      const data = await api({
        ...address
      })
      if (data) {
        Toast({
          type: 'success',
          message: 'success'
        })
      }
      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)
export const addressById = createAsyncThunk(
  `${namespace}/address`,
  async (payload, { extra: { api, Toast } }) => {

    const { address } = GetAddressById
    address.body = payload
    try {
      const { data, message, status } = await api({
        ...address
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data, message, status }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

export const updateAddressById = createAsyncThunk(
  `${namespace}/address`,
  async (payload, { extra: { api, Toast } }) => {

    const { editAddress } = UpadateAddress
    editAddress.body = payload
    try {
      const res = await api({
        ...editAddress
      })
      
      return res
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const updateDefaultAddress = createAsyncThunk(
  `${namespace}/updateDefaultAddress`,
  async (payload, { extra: { api, Toast } }) => {

    const { updateDefaultAddressApi } = UpadateAddress
    updateDefaultAddressApi.params = payload
    try {
      const { data, message, status } = await api({
        ...updateDefaultAddressApi
      })
      if (data) {
        Toast({
          type: 'success',
          message
        })
      }
      return { data, message, status }
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const deleteAddress = createAsyncThunk(
  `${namespace}/address`,
  async (payload, { extra: { api, Toast } }) => {

    const { address } = DeleteAddress
    address.body = payload
    try {
      const data = await api({
        ...address
      })

      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
    return payload;
  }
)

// CART
export const cartList = createAsyncThunk(
  `${namespace}/cartList`,
  async (payload, { extra: { api, Toast } }) => {

    const { cartListApi } = Cart

    cartListApi.body = payload
    try {
      const data = await api({
        ...cartListApi
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

// order


export const createOrderApi = createAsyncThunk(
  `${namespace}/orderCreate`,
  async (payload, { extra: { api, Toast } }) => {

    const { orderCreate } = orders

    orderCreate.body = payload
    try {
      const data = await api({
        ...orderCreate
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const checkPaymentStatusApi = createAsyncThunk(
  `${namespace}/checkPaymentStatusApi`,
  async (payload, { extra: { api, Toast } }) => {

    const { paymentStatusApi } = orders
    paymentStatusApi.params = payload

    try {
      const data = await api({
        ...paymentStatusApi
      })
      return data

    } catch (error) {
      // Toast({ type: 'error', message: error?.message })
    }
  }
)
export const updateAddress = createAsyncThunk(
  `${namespace}/updateAddress`,
  async (payload, { extra: { api, Toast } }) => {
    updateAddressApi.body = payload
    try {
      const data = await api({
        ...updateAddressApi
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const getStreetAddressFromLatLng = createAsyncThunk(
  `${namespace}/getStreetAddressFromLatLng`,
  async (payload, { extra: { api, Toast } }) => {
    const { addressFromLatLng } = getAddressFromLatLng
    addressFromLatLng.params = payload
    try {
      const data = await api({
        ...addressFromLatLng
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const getDirectionsFromLatLng = createAsyncThunk(
  `${namespace}/updateAddress`,
  async (payload, { extra: { api, Toast } }) => {
    const { directionsFromAndToLatLng } = getDirectionsFromAndToLatLng
    directionsFromAndToLatLng.body = payload
    try {
      const data = await api({
        ...directionsFromAndToLatLng
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const getPastOrders = createAsyncThunk(
  `${namespace}/ordersList`,
  async (payload, { extra: { api, Toast } }) => {

    const { ordersList } = PastOrders

    try {
      const data = await api({
        ...ordersList
      })
      return data

    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const orderTracking = createAsyncThunk(
  `${namespace}/orderTrack`,
  async (payload, { extra: { api, Toast } }) => {

    const { orderTrack } = TrackOrder
    orderTrack.params = payload
    try {
      const data = await api({
        ...orderTrack
      })
      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)

export const cancelOrder = createAsyncThunk(
  `${namespace}/cancelOrder`,
  async (payload, { extra: { api, Toast } }) => {

    const { cancelTheOrder } = CancelOrder
    cancelTheOrder.params = payload
    try {
      const data = await api({
        ...cancelTheOrder
      })
      return data
    } catch (error) {
      Toast({ type: 'error', message: error?.message })
    }
  }
)