import axios from 'axios';

export const axiosInstance = axios.create({
  headers: {}
});

export const cancelTokenSource = axios.CancelToken.source();

export const setAuthToken = (token) => {
  return localStorage.setItem('token', token);
};

export const setUserDetail = (data) => {
  return localStorage.setItem('userdata', JSON.stringify(data));
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const setLocalStorageData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageData = (key) => {
  if (!key) return null;
  return JSON.parse(localStorage.getItem(key));
};


export const logout = () => {
  axiosInstance.defaults.headers.common.token = '';
  localStorage.removeItem("token");
  window.location.pathname = '/';
};

export const statusHelper = (data) => {
  if (data.status === 401 || data.status === 403) {
    setTimeout(() => logout(), 1000);
  }
  return {
    status: data.status,
    ...data.data
  };
};

export const scrollHelper = ({ isTop, isSmooth = true }) => {
  if (window) {
    const behavior = isSmooth ? 'smooth' : 'auto';

    if (isTop) {
      return window.scrollTo({ top: 0, behavior });
    }
  }

  return null;
};

export const getOrderStatus = (status) => {
  switch(status){
    case "PAYMENT_PENDING":
      return "PAYMENT PENDING"
    case 'WAITING_FOR_PAYMENT':
        return 'WAITING FOR PAYMENT'
    case "ORDER_CREATED": 
      return "WAITING FOR CONFIRMATION"
    case "CONFIRMED": 
      return "CONFIRMED"
    case "PROCESSING": 
      return "PROCESSING"
    case "READY_TO_DELIVER": 
      return "READY TO DELIVER"
    case "PICKEDUP": 
      return "PICKEDUP"
    case "DELIVERED": 
      return "DELIVERED"
    case "CANCELLED": 
      return "CANCELLED"
    default: 
      return ""
  }
}