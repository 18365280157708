
/* eslint-disable no-console */
import { format, parse } from 'date-fns';
import { Toast } from '../service/toast';

export const getSafe = (fn, defaultValue = '') => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};
export const handleData = (keyValue, defaultValue, className = '',styles = {}) => {
  return (
      <td className={`${className}`} style={{ ...styles }}>
          {ternaryCondition(keyValue, keyValue, defaultValue)}
      </td>
  );
};

export const ternaryCondition = (val1, val2 = '', defaultValue = '') => {
  return val1 ? val2 : defaultValue;
};

export const getSNo = (index, page = 1, pageCount = 10) => {
  return parseInt(((page - 1) * pageCount), 10) + index + 1;
};

export const replaceEmpty = (val, defaultValue = null) => {
  if (val) return val;

  return defaultValue;
};

export const dateFormatter = (
  date,
  stringFormat = 'dd/MM/yyyy'
) => {
  if (!date) return '';
  return format(date, stringFormat);
};

export const parseLocalDate = (date, options) => {
  const {
    needParse = true,
    currentDateFormat = 'dd/MM/yyyy',
    formatDate = false,
    dateFormat = 'dd LLL yyyy'
  } = options || {};

  if (!date) return '';

  try {
    const parsedDate = needParse
      ? parse(date, currentDateFormat, new Date())
      : date;

    return formatDate ? dateFormatter(parsedDate, dateFormat) : parsedDate;
  } catch (e) {
    console.log(e, 'Parse Local Date Error');
    return '';
  }
};


export const getValidValue = (data, type = 'normal', replaceValue = null) => {
  if (type === 'select') return data && data.label && data.value ? data.value : replaceValue;

  if (typeof data === 'boolean') return data;

  if (Array.isArray(data) && data?.length === 0) return replaceValue;

  if (typeof data === 'object' && Object.keys(data || {})?.length === 0)
    return replaceValue;

  if (typeof data === 'undefined') return replaceValue;

  return data;
};

/**
 * Displays the short number count (eg:) 325K,2.4M,...etc
 *
 * @param {number | string} val the number which needs to be displayed for short count.
 * @returns {string} returns the short count (e.g) if 3000 is the input then it returns 3K as output
 */

export const countHelper = (val) => {
  let displayCount = '';

  if (Number.isNaN(val)) return displayCount;

  displayCount = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(val);

  return displayCount;
};


export const getFormErrorMessage = (errors, fieldArrayError, name) => {
  if (!name || !errors) return null;

  if (!fieldArrayError && name) {
    return errors[name];
  }

  return errors[fieldArrayError] ? errors[fieldArrayError] : null
};


export const convertToValidObject = (obj) => {
  const currentObj = { ...obj };

  Object.keys(currentObj)
    .filter((key) => typeof currentObj[key] !== 'boolean')
    .forEach((key) => {
      if (!currentObj[key]) {
        delete currentObj[key];
      }
    });

  return { ...currentObj };
};

export const addressFormatter = (deliveryAddress) => {
  const { addressLine1, addressLine2, city, state, country, pincode } =
    deliveryAddress || {};

  const addressArray = [
    getValidValue(addressLine1),
    getValidValue(addressLine2),
    getValidValue(city),
    getValidValue(state),
    getValidValue(country)
  ].filter((data) => data !== getValidValue(undefined));

  return `${addressArray.join(' , ')} - ${pincode}`;
};


export const urlCombiner = (url) => {
  const protocol = ['h', 't', 't', 'p'].join('');

  return `${protocol}://${url}`;
};

export const defaultValidator = (val, defaultValue) => {
  return val || defaultValue;
};

export const ToastError = (err) => {
  return Toast({ type: 'error', message: err.Error || err.message || 'Error' });
};

export const placeCaretAtEnd = (el) => {

  if (typeof window.getSelection !== "undefined"
    && typeof document.createRange !== "undefined") {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== "undefined") {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
  el.focus();
}

export const generateQuery = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((param) => params.append(param, query[param]));
  return Object.keys(query).length > 0 ? `?${params.toString()}` : '';
};

export const IMAGE_BUCKET_FOLDERS = {
  FEEDS: "FEEDS",
  ALUMNI_PROFILE: "ALUMNI_PROFILE",
  USER_PROFILE: "USER_PROFILE",
  ANCILLARY_COURSE: "ANCILLARY_COURSE",
  OTHERS: "OTHERS"
};

export const getLocationName = (records) => {
  let location = ''
  if (records?.length > 0) {
    const [{ locationName }] = records
    location = locationName
  }
  return location;
}

export const userRoleList = [
  { label: 'Super Admin', value: 1 },
  { label: 'Admin', value: 2 },
  { label: 'Sales Person', value: 3 },
  { label: 'Client', value: 4 },
];

export const getUserRole = (id) => {
  const role = userRoleList.find((role) => role.value === id);
  return role ? role.label : '';
};

export const categoryList = [
  { label: 'POLY', value: 1 },
  { label: 'MONO', value: 2 },
  { label: 'MONO-HALFCUT', value: 3 },
  { label: 'BIFICEAL', value: 3 },
]
export const getCategoryList = (id) => {
  const category = categoryList.find((category) => category.value === id);
  return category ? category.label : '';
};


export const ShowArrayInTable = (array, count, item) => {
  const returnHtml = (txt, arr) => {
    return (
      <td className='array_in_table' title={arr?.join(', ')}>
        <span className='ellipses'>{txt}</span>
        {/* {arr.length > 1 && <p className='list-array-count'> +{arr.length - count}</p>} */}
      </td>
    )
  }

  if (array.length === 0) {
    return returnHtml('-', [])
  }
  const mappedArray = array.slice(0, count).map(ar => ar[item])
  const str = mappedArray.join(', ');


  return returnHtml(str, mappedArray);
}

export const validateFilesize = (files) => {
  let isValid = true;
  let maxFilesize = 204800; // 200kb
  if (files.length > 0) {
    if (files[0]?.size > maxFilesize) {
      Toast('error', 'Max File size is 200kb');
      isValid = false;
    } else isValid = true;
  } else isValid = false;
  return isValid;
};