import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInput from '../FormInput/customInput';
import { FaArrowLeftLong } from "react-icons/fa6";


const Sidebar = ({ isOpen, toggleSidebar }) => {

  const [activeSection, setActiveSection] = useState('login');
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobile: '',
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required('Enter Your Mobile Number'),
    }),

    onSubmit: async (values) => {
    }

  });
  const registerValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobile: '',
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required(' Enter Your Mobile Number'),
      name: Yup.string().required(' Enter Your Name'),
      email: Yup.string().required(' Enter Your Email'),
    }),

    onSubmit: async (values) => {
    }

  });
  return (
    <>
      {activeSection === 'login' && (
        <>
          {/* desktop */}
          <div className={`offcanvas offcanvas-end d-lg-block d-none ${isOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasLogin">
            <div className="offcanvas-body">
              <button type="button" className={`btn-close ${isOpen ? 'show' : ''}`} onClick={toggleSidebar}  ></button>

              <div className="offcanvas-form">
                <div className="login-head">
                  <h4 className="title">Login</h4>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className='mb-4'>
                      <CustomInput
                        placeholder='Mobile Number'
                        name='mobile'
                        type='text'
                        label='Mobile Number'
                        value={validation.values.email || ''}
                        labelClassName='col-form-label'
                        validation={validation}
                      />
                    </div>
                    {/* <div className='mb-4'>
                                <CustomInput
                                    placeholder='password'
                                    name='password'
                                    type='password'
                                    label='Password'
                                    value={validation.values.password || ''}
                                    labelClassName='col-form-label'
                                    validation={validation}
                                />
                            </div> */}

                    <div className='text-center '>
                      <button name="submit" value="submit" type="submit" className="btn btn-primary w-100 d-block btn-hover-2"><span>Sign In</span></button>
                    </div>
                  </form>

                  <p className="text-center m-t30">Not registered? {' '}
                    <a className={`toggle-button ${activeSection === 'register' ? 'show' : ''}`} onClick={() => handleSectionChange('register')} data-bs-toggle="offcanvas" href="#offcanvasRegister" role="button" aria-controls="offcanvasRegister">Register here</a>
                  </p>
                </div>

              </div>
            </div>
          </div>
          {/* mobile view */}
          <div className={`offcanvas offcanvas-end d-lg-none ${isOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasLoginMobile">
            <div className="offcanvas-body">
              <button type="button" className={`mobile-btn-close ${isOpen ? 'show' : ''}`} onClick={toggleSidebar}  >
                <FaArrowLeftLong />
              </button>

              <div className="offcanvas-form">
                <div className="login-head">
                  <h4 className="title">Login</h4>
                  <p>Enter your phone number to continue</p>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className='mb-4'>
                    <CustomInput
                      placeholder='Mobile Number'
                      name='mobile'
                      type='text'
                      label='Mobile Number'
                      value={validation.values.email || ''}
                      labelClassName='col-form-label'
                      validation={validation}
                    />
                  </div>
                  {/* <div className='mb-4'>
                                <CustomInput
                                    placeholder='password'
                                    name='password'
                                    type='password'
                                    label='Password'
                                    value={validation.values.password || ''}
                                    labelClassName='col-form-label'
                                    validation={validation}
                                />
                            </div> */}

                  <div className='text-center '>
                    <button name="submit" value="submit" type="submit" className="btn btn-primary w-100 d-block btn-hover-2"><span>Sign In</span></button>
                  </div>
                </form>

                <p className="text-center m-t30">Not registered? {' '}
                  <a className={`toggle-button ${activeSection === 'register' ? 'show' : ''}`} onClick={() => handleSectionChange('register')} data-bs-toggle="offcanvas" href="#offcanvasRegister" role="button" aria-controls="offcanvasRegister">Register here</a>
                </p>
              </div>
            </div>
          </div>
        </>

      )}

      {activeSection === 'register' && (
        <>
          <div className={`offcanvas offcanvas-end ${isOpen ? 'show' : ''}`} tabindex="-1" id="offcanvasRegister">
            <div className="offcanvas-body">
              <button type="button"
                className={`btn-close ${isOpen ? 'show' : 'hide'}`}
                onClick={() => toggleSidebar()}  >
                <FaArrowLeftLong />
              </button>
              <div className="offcanvas-form">
                <div className="login-head">
                  <h4 className="title">Create An Account</h4>
                  <p>Create an account with the new phone number</p>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    registerValidation.handleSubmit();
                    return false;
                  }}
                >
                  <div >
                    <CustomInput
                      placeholder='Name'
                      name='name'
                      type='text'
                      label='Name'
                      value={registerValidation.values.name || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>
                  <div>
                    <CustomInput
                      placeholder='Mobile Number'
                      name='mobile'
                      type='text'
                      label='Mobile Number'
                      value={registerValidation.values.mobile || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>
                  <div >
                    <CustomInput
                      placeholder='Email '
                      name='email'
                      type='text'
                      label='Email'
                      value={registerValidation.values.email || ''}
                      labelClassName='col-form-label'
                      validation={registerValidation}
                    />
                  </div>

                  <button name="submit" value="submit" type="submit" className="btn btn-primary w-100 d-block btn-hover-2">
                    <span>Sign Up</span>
                  </button>
                </form>

                <p className="text-center m-t30 isNew">Have an account ? {' '}
                  <span className={`toggle-button ${activeSection === 'login' ? 'show' : ''}`} onClick={() => handleSectionChange('login')} >Login</span>
                </p>
              </div>
            </div>
          </div>
        </>

      )}



    </>
  );
};

export default Sidebar;
