import React from "react";
import AddressPrimaryIc from 'assets/icons/profile/address_primary_ic.svg'

export const AskLocation = ({
    toggleLocationModal,
    allowCurrentLocation
}) => {
    return (
        <div className="loc_overlay">
            <div className="loc_container">
                <div className="loc_left_comp">
                    <img src={AddressPrimaryIc} alt="pointer" className="loc_left_ic"/>
                </div>
                <div className="loc_right_comp">
                    <p className="loc_nrm">To deliver as quickly as possible, we would like your current location</p>
                    <div className="mt-4 d-flex justify-content-end">
                        <button className="loc_btn_1" type="button" onClick={() => toggleLocationModal()}>
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="loc_btn_text_1">Select Address</p>
                            </div>
                        </button>
                        {/* <button className="loc_btn_2" type="button" onClick={() => allowCurrentLocation()}>
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="loc_btn_text_2">Use Current Location</p>
                            </div>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}